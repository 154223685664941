import React, { Component } from "react";
// import ReactCSSTransitionGroup from "react-transition-group"; // ES6
import "./ButtonBar.css";
import piconList from "./piconListArray.js";

export default class Buttonz extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      playList: piconList,
      buttonArray: [],
      labelArray: [],
      lastSwitchIndex: -1,
      // date: Date.now(),
      nextUpdatetime: Math.floor(Date.now() + 5000 + Math.random() * 5000)
    };

    this.shuffleArray = this.shuffleArray.bind(this);
    this.checkPiconTime = this.checkPiconTime.bind(this);
  }

  componentWillMount() {
    //console.log("will mount");
  }

  componentDidMount() {
    //console.log("did mount");
    this.shuffleArray();
    //console.log(this.state.playList);
    this.fillButtonArray();
    this.interval = setInterval(() => this.setState({ time: Date.now() }), 200);
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log("did update");
    this.checkPiconTime();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  shuffleArray() {
    // console.log("shuffle");
    const shuffle = require("shuffle-array");
    var shuffleList = this.state.playList;
    shuffle(shuffleList);
    this.setState({ playList: shuffleList });
  }

  fillButtonArray() {
    const buttonCount = 5;
    var tempArray = [];
    while (tempArray.length < buttonCount) {
      tempArray.push(this.randomPicon());
    }
    this.setState({ buttonArray: tempArray });
  }

  randomPicon() {
    var tempPiconArray = this.state.playList;
    var myPicon = tempPiconArray.shift();
    var myLabel = myPicon.split(".", 1);
    var tempLabelArray = this.state.labelArray;
    tempLabelArray.push(myLabel[0]);
    this.setState({ labelArray: tempLabelArray });
    var picon = require("../images/picon_150/" + myPicon);
    tempPiconArray.push(myPicon);
    this.setState({ playList: tempPiconArray });
    //console.log(picon);
    return picon;
  }

  checkPiconTime() {
    //console.log("check timeout");
    if (Date.now() >= this.state.nextUpdatetime) {
      const shuffle = require("shuffle-array");
      var piconIndex = shuffle.pick([0, 1, 2, 3, 4]);
      while (this.state.lastSwitchIndex === piconIndex) {
        piconIndex = shuffle.pick([0, 1, 2, 3, 4]);
      }
      this.setState({ lastSwitchIndex: piconIndex });
      var tempPiconArray = this.state.playList;
      var myPicon = tempPiconArray.shift();
      //console.log(myPicon);
      var myLabel = myPicon.split(".", 1);
      var picon = require("../images/picon_150/" + myPicon);
      var tempLabel = this.state.labelArray;
      tempLabel[piconIndex] = myLabel[0];
      this.setState({ labelArray: tempLabel });
      var tempButtons = this.state.buttonArray;
      tempButtons[piconIndex] = picon;
      this.setState({ buttonArray: tempButtons });
      //this.setState({ buttonArray[piconIndex]: picon });
      //this.setState.buttonArray[piconIndex] = picon;
      tempPiconArray.push(myPicon);
      this.setState({ playList: tempPiconArray });
      this.setState({
        nextUpdatetime: Math.floor(Date.now() + 3000 + Math.random() * 3000)
      });
    }
  }

  render() {
    return (
      // <div className="wrapper">
      <div className="content">
        <div className="row">
          <div className="cell" />
          <div className="cell" />
          <div className="cell" />
          <div className="cell" />
          <div className="cell">
            <button className="picon">
              <img
                src={this.state.buttonArray[0]}
                alt={this.state.labelArray[0]}
                // onClick={() => console.log(this.state.labelArray[0])}playInsertClip(buttonIndex)
                onClick={() => this.props.es6Function(this.state.labelArray[0])}
              />
            </button>
          </div>
          <div className="cell">
            <button className="picon">
              <img
                src={this.state.buttonArray[1]}
                alt={this.state.labelArray[1]}
                onClick={() => this.props.es6Function(this.state.labelArray[1])}
              />
            </button>
          </div>
          <div className="cell">
            <button className="picon">
              <img
                src={this.state.buttonArray[2]}
                alt={this.state.labelArray[2]}
                onClick={() => this.props.es6Function(this.state.labelArray[2])}
              />
            </button>
          </div>
          <div className="cell">
            <button className="picon">
              <img
                src={this.state.buttonArray[3]}
                alt={this.state.labelArray[3]}
                onClick={() => this.props.es6Function(this.state.labelArray[3])}
              />
            </button>
          </div>
          <div className="cell">
            <button className="picon">
              <img
                src={this.state.buttonArray[4]}
                alt={this.state.labelArray[4]}
                onClick={() => this.props.es6Function(this.state.labelArray[4])}
              />
            </button>
          </div>
          <div className="cell" />
          <div className="cell" />
          <div className="cell" />
          <div className="cell" />
        </div>
      </div>
      // </div>
    );
  }
}
