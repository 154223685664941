import React, { Component } from "react";
import { Player, ControlBar, BigPlayButton, LoadingSpinner } from "video-react";
import HLSSource from "./HLSSource";
import "video-react/dist/video-react.css";
import Bowser from "bowser";
import Buttonz from "./ButtonBar.js";

const browser = Bowser.getParser(window.navigator.userAgent);
const bName = browser.getBrowserName();
var posterSource = "";
if (bName !== "Safari") posterSource = require("../assets/poster.png");

export default class NSPlayer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      firstPlay: 1,
      jumpIn: 1,
      playerActive: false,
      switchTime: 0,
      playList: [],
      insertCLipActive: false,
      playListCurIndex: 0,
      jumpTime: 0,
      totalClips: 0
    };

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.load = this.load.bind(this);
    this.seek = this.seek.bind(this);
    this.updateData = this.updateData.bind(this);
    this.shuffleArray = this.shuffleArray.bind(this);
    this.nameToIndex = this.nameToIndex.bind(this);
    this.playNextClip = this.playNextClip.bind(this);
    this.playInsertClip = this.playInsertClip.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  es6Function = value => {
    //console.log("callback " + value);
    this.playInsertClip(value);
  };

  componentWillMount() {
    // console.log("mount");
    this.parseEDL();
  }

  componentDidMount() {
    // subscribe state change
    this.refs.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("did update");
    if (this.state.playerActive === true) {
      return 0;
    }

    if (this.state.totalClips !== prevState.totalClips) {
      this.shuffleArray();
    }
    this.checkTime();
    if (typeof this.state.player != "undefined") {
      if (this.state.player.paused === false) {
        this.playNextClip();
        this.setState({ playerActive: true });
      }
    }
  }

  parseEDL() {
    // console.log("parse edl");
    var csvFilePath = "http://nonself.tv/video/csv/nonself_edl_07.csv"; // CA
    // var csvFilePath = "http://nonself.me/video/csv/nonself_edl_07.csv"; // paris
    var Papa = require("papaparse/papaparse.min.js");
    Papa.parse(csvFilePath, {
      header: true,
      download: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      // Here this is also available. So we can call our custom class method
      complete: this.updateData
    });
  }

  updateData(result) {
    const EDL = result.data;
    this.setState({ EDL }); // or shorter ES syntax: this.setState({ data });
    const clips = EDL.length;
    this.setState({ totalClips: clips });
  }

  shuffleArray() {
    // console.log("shuffle");
    const shuffle = require("shuffle-array");
    const shuffleList = Array.from(Array(this.state.totalClips).keys());
    shuffle(shuffleList);
    this.setState({ playList: shuffleList });
  }

  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({ player: state, playerState: state.playerState });
    this.checkTime();
  }

  checkTime() {
    //console.log("checkTime");

    if (typeof this.state.player != "undefined") {
      if (
        this.state.player.paused === true ||
        this.state.player.seeking === true
      ) {
        return 0;
      }
      var myTime = this.state.player.currentTime;
      //console.log("myTime " + myTime);

      // hack to get safari started
      // if (myTime <= this.state.jumpIn) {
      //   // console.log("Check Time");
      //   var clipIndex = Math.floor(Math.random() * this.state.totalClips);
      //   clipIndex = Math.min(clipIndex, this.state.totalClips - 1);
      //   // console.log("clipIndex = " + clipIndex);
      //   this.playInsertClip(clipIndex);
      //   // this.setState({ jumpIn: -1 });
      //   return 0;
      // }

      if (myTime >= this.state.jumpTime) {
        this.playNextClip();
        if (this.state.insertCLipActive) {
          //this.setState({ switchTime: Date.now() + 1000 });
          // blindly set it to false?
          this.setState({ insertCLipActive: false });
          this.setState({ jumpIn: -1 });
        }
      }
    }
  }

  playNextClip() {
    //console.log("playnext");
    if (typeof this.state.player != "undefined") {
      if (
        this.state.player.seeking === true ||
        this.state.insertCLipActive === true
      ) {
        return 0;
      }

      if (Date.now() < this.state.switchTime) return 0;
      var myIndex = this.state.playListCurIndex;
      myIndex++;

      if (myIndex >= this.state.totalClips) {
        this.setState({ playListCurIndex: 0 });
        myIndex = 0;
        this.shuffleArray();
      }

      var EDLIndex = this.state.playList[myIndex];
      var nextClip = this.state.EDL[[EDLIndex]];
      var jump = nextClip.In;
      var clipOut = nextClip.Out;
      //console.log("jumptime " + jump);
      var outputTrim = 8; //trim applied to edl in framea
      var inputTrim = 4;
      var inpointTrimmed = jump + inputTrim;
      var outpointTrimmed = clipOut - outputTrim;

      if (this.state.firstPlay) {
        //console.log("first play");
        this.setState({ firstPlay: 0 });
        this.setState({ jumpTime: 220 / 24.0 });
        this.refs.player.seek(0);
        return 0;
      }

      this.setState({ jumpTime: outpointTrimmed / 24.0 });
      this.setState({ playListCurIndex: myIndex });
      this.refs.player.seek(inpointTrimmed / 24.0);
    }
  }

  nameToIndex(name) {
    // console.log("LOOKING FOR " + name);
    var index = this.state.EDL.findIndex(x => x["Marker Name"] === name);
    // console.log(name + " index result " + index);
    return index;
  }

  // this.playInsertClip(name);

  playInsertClip(buttonIndex) {
    // console.log("playInsertClip");
    if (typeof this.state.EDL != "undefined") {
      this.pause();
      if (typeof buttonIndex == "string") {
        var indexstring = buttonIndex;
        buttonIndex = this.nameToIndex(buttonIndex);
        if (buttonIndex < 0) {
          console.log(
            "Lookup failed for " + indexstring + " result " + buttonIndex
          );
          this.play();
          return;
        }
      }
      //var myTime = this.state.player.currentTime;
      //console.log("Insert clip Entry player time " + myTime);
      this.setState({ switchTime: Date.now() + 1000 });
      this.setState({ insertCLipActive: true });
      this.forceUpdate();
      var nextClip = this.state.EDL[[buttonIndex]];
      var jump = nextClip.In;
      var clipOut = nextClip.Out;
      var inputTrim = 4;
      var outputTrim = 8;
      var inpointTrimmed = jump + inputTrim;
      var outpointTrimmed = clipOut - outputTrim;
      this.setState({ jumpTime: outpointTrimmed / 24.0 });
      this.refs.player.seek(inpointTrimmed / 24.0);
      this.play();
    }
  }

  play() {
    this.refs.player.play();
  }

  pause() {
    this.refs.player.pause();
  }

  load() {
    this.refs.player.load();
  }

  seek(seconds) {
    return () => {
      this.refs.player.seek(seconds);
    };
  }

  toggleFullscreen() {
    this.refs.player.toggleFullscreen();
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div>
            <Buttonz es6Function={this.es6Function} />
          </div>
          <Player
            ref="player"
            fluid={true}
            autoplay={false}
            loop={true}
            playsInline={true}
            aspectRatio={"16:9"}
            poster={posterSource}
          >
            <BigPlayButton position="center" />
            {/* <ClickableComponent /> */}
            <HLSSource
              isVideoChild
              src="http://nonself.tv/video/nonself_0617/playlist.m3u8"
              //src="http://nonself.me/video/nonself_0617/playlist.m3u8" //paris
            />
            {/* <LoadingSpinner disabled /> */}
            <LoadingSpinner />
            <ControlBar disabled />
          </Player>
        </div>
      </React.Fragment>
    );
  }
}
