const piconList = [
  "absolved.png",
  "adam.png",
  "adaptable.png",
  "adverse.png",
  "agile.png",
  "alacritous.png",
  "alone.png",
  "ashamed.png",
  "atomic.png",
  "avuncular.png",
  "backwards.png",
  "beautiful.png",
  "biblical.png",
  "binary.png",
  "blind.png",
  "blue.png",
  "bruised.png",
  "calm.png",
  "caring.png",
  "casual.png",
  "catholic.png",
  "cautious.png",
  "chic.png",
  "circumspect.png",
  "classical.png",
  "complete.png",
  "conscious.png",
  "continuous.png",
  "courageous.png",
  "creative.png",
  "daring.png",
  "deep.png",
  "demonic.png",
  "didactic.png",
  "disappointed.png",
  "disguised.png",
  "docile.png",
  "dust.png",
  "educated.png",
  "equal.png",
  "eternal.png",
  "ethereal.png",
  "evolved.png",
  "exempt.png",
  "expected.png",
  "faithful.png",
  "fallen.png",
  "fanciful.png",
  "female.png",
  "feminine.png",
  "flagrant.png",
  "follower.png",
  "formulaic.png",
  "free.png",
  "gay.png",
  "gemini.png",
  "genius.png",
  "graceful.png",
  "green.png",
  "honest.png",
  "hostile.png",
  "ignorant.png",
  "impartial.png",
  "impervious.png",
  "included.png",
  "indifferent.png",
  "innocent.png",
  "internal.png",
  "late.png",
  "lax.png",
  "lazy.png",
  "liberated.png",
  "light.png",
  "linear.png",
  "marginal.png",
  "masculine.png",
  "mature.png",
  "medium.png",
  "miasma.png",
  "modest.png",
  "musical.png",
  "mysterious.png",
  "natural.png",
  "neurotic.png",
  "nimble.png",
  "nostalgic.png",
  "obstinate.png",
  "obvious.png",
  "original.png",
  "other.png",
  "pensive.png",
  "perfect.png",
  "peri.png",
  "pessimistic.png",
  "philosophical.png",
  "placid.png",
  "poetic.png",
  "polished.png",
  "political.png",
  "porous.png",
  "prescribed.png",
  "proper.png",
  "racist.png",
  "random.png",
  "redeemed.png",
  "reflexive.png",
  "religious.png",
  "resolved.png",
  "rigid.png",
  "romantic.png",
  "rude.png",
  "sanguine.png",
  "sassy.png",
  "satisfied.png",
  "selfish.png",
  "sensible.png",
  "sensitive.png",
  "sexy.png",
  "shakespeare.png",
  "shiny.png",
  "sick.png",
  "sincere.png",
  "sociable.png",
  "solemn.png",
  "sorry.png",
  "specious.png",
  "stable.png",
  "stupid.png",
  "subtle.png",
  "supernatural.png",
  "susceptable.png",
  "sweet.png",
  "tactical.png",
  "tainted.png",
  "tensile.png",
  "tired.png",
  "tranquil.png",
  "typical.png",
  "understood.png",
  "unnatural.png",
  "verbal.png",
  "violent.png",
  "vocal.png",
  "vulgar.png",
  "why.png",
  "wild.png",
  "young.png"
];

export default piconList;
