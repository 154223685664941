import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import "video-react/dist/video-react.css";
import * as serviceWorker from "./serviceWorker";
import NSPlayer from "./components/NSPlayer";
//import Buttonz from "./components/ButtonBar";

ReactDOM.render(<NSPlayer />, document.getElementById("root"));
// ReactDOM.render(<Buttonz />, document.getElementById("root"));
serviceWorker.unregister();
